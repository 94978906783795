import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrinterList from './components/PrinterList';
import PrintOptions from './components/PrintOptions';
import UploadFile from './components/UploadFile';

function App() {
  return (
    <Router>
      <div className="container mt-4">
        <h1 className="mb-4">System samoobsługowego drukowania</h1>
        <Routes>
          <Route path="/" element={<PrinterList />} />
          <Route path="/options/:printerId" element={<PrintOptions />} />
          <Route path="/upload/:jobCode" element={<UploadFile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
