import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function PrinterList() {
  const [printers, setPrinters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrinters = async () => {
      try {
        const response = await axios.get('/api.php?action=getPrinters');
        if (response.data.success) {
          setPrinters(response.data.data);
        } else {
          setError('Nie udało się pobrać listy drukarek.');
        }
      } catch (err) {
        setError('Wystąpił błąd podczas pobierania listy drukarek.');
        console.error('Błąd:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPrinters();
  }, []);

  if (loading) {
    return <div className="text-center mt-5">Ładowanie...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  return (
    <div>
      <h2 className="mb-4">Dostępne drukarki</h2>
      {printers.length === 0 ? (
        <p>Brak dostępnych drukarek.</p>
      ) : (
        <ul className="list-group">
          {printers.map((printer) => (
            <li key={printer.id} className="list-group-item">
              <Link to={`/options/${printer.id}`} className="d-flex justify-content-between align-items-center text-decoration-none">
                <span>{printer.name}</span>
                <span className="badge bg-primary rounded-pill">{printer.location}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default PrinterList;
