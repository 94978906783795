import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function UploadFile() {
  const { jobCode } = useParams();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Proszę wybrać plik do przesłania.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('job_code', jobCode);

    try {
      const response = await axios.post('/print.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.success) {
        setMessage('Plik został przesłany pomyślnie. Twój dokument zostanie wkrótce wydrukowany.');
      } else {
        setMessage('Nie udało się przesłać pliku. Spróbuj ponownie.');
      }
    } catch (error) {
      console.error('Błąd:', error);
      setMessage('Wystąpił błąd. Spróbuj ponownie.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h2>Prześlij plik do wydruku</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="file" className="form-label">Wybierz plik</label>
          <input
            type="file"
            className="form-control"
            id="file"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.txt"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={uploading}>
          {uploading ? 'Przesyłanie...' : 'Prześlij i drukuj'}
        </button>
      </form>
      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
}

export default UploadFile;
