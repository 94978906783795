import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

function PrintOptions() {
  const { printerId } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    paperSize: 'A4',
    color: 'bw',
    duplex: false
  });
  const [jobCode, setJobCode] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOptions(prevOptions => ({
      ...prevOptions,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api.php?action=createPrintJob', {
        printerId,
        options: JSON.stringify(options)
      });
      if (response.data.success) {
        setJobCode(response.data.job_code);
      } else {
        alert('Nie udało się utworzyć zadania drukowania. Spróbuj ponownie.');
      }
    } catch (error) {
      console.error('Błąd:', error);
      alert('Wystąpił błąd. Spróbuj ponownie.');
    }
  };

  return (
    <div>
      <h2>Wybierz opcje drukowania</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Rozmiar papieru</label>
          <select
            name="paperSize"
            className="form-select"
            value={options.paperSize}
            onChange={handleChange}
          >
            <option value="A4">A4</option>
            <option value="A3">A3</option>
            <option value="Letter">Letter</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Kolor</label>
          <div>
            <div className="form-check">
              <input
                type="radio"
                name="color"
                value="bw"
                checked={options.color === 'bw'}
                onChange={handleChange}
                className="form-check-input"
                id="bw"
              />
              <label className="form-check-label" htmlFor="bw">Czarno-biały</label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                name="color"
                value="color"
                checked={options.color === 'color'}
                onChange={handleChange}
                className="form-check-input"
                id="color"
              />
              <label className="form-check-label" htmlFor="color">Kolorowy</label>
            </div>
          </div>
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            name="duplex"
            checked={options.duplex}
            onChange={handleChange}
            className="form-check-input"
            id="duplex"
          />
          <label className="form-check-label" htmlFor="duplex">Dwustronny</label>
        </div>
        <button type="submit" className="btn btn-primary">Utwórz zadanie drukowania</button>
      </form>
      {jobCode && (
        <div className="mt-4">
          <h3>Zeskanuj ten kod QR, aby przesłać plik:</h3>
          <QRCodeSVG value={`${window.location.origin}/upload/${jobCode}`} />
        </div>
      )}
    </div>
  );
}

export default PrintOptions;
